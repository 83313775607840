<template>
  <div class="agenda">
    <section class="roomHeader home-menu-header" :style="'var(--userColor)'">
      <div class="is-flex is-justify-content-center">
        <img v-if="$store.state.setup.headerLogo" class="logo" :src="srcHeaderMenu" />
        <span v-else>{{ appTitle }}</span>
      </div>
    </section>
    <section class="section mainSection">
      <div class="tabs-parent">
        <b-tabs expanded v-model="agendaActiveTab">
          <!--<b-tab-item label="Agenda" class="px-0"> -->
            <b-collapse class="card mb-4" animation="slide" v-for="(dia, index) of dias" :key="index"
              :open="diaActiveTab == index" @open="diaActiveTab = index" :aria-id="'contentIdForA11y5-' + index">

              <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-' + index"
                  :aria-expanded="props.open">
                  <div class="card-header-title">
                    <div :class="'circuloTitulo circulo_' + index">
                      <div class="circuloTituloInner"></div>
                    </div>
                    <span class="has-text-weight-bold mr-2">Día {{ index + 1 }} </span>
                    <span class="spacer"></span>
                    <span>{{ FormatDate(dia) }}</span>
                  </div>
                  <a class="card-header-icon">
                    <d-icon icon="FaChevronDown" size="is-small" :class="{open: props.open}" />
                  </a>
                </div>
              </template>
              <div class="card-content p-0">
                <div class="content">
                  <div v-for="evento in EventosInDia(index)" :key="evento.id" class="card evento"
                    :class="{ active: evento.activo, old: evento.yapaso }">
                    <div class="card-content px-1 py-5">
                      <div class="horaylugar mb-2 is-size-7">
                        <span v-if="evento.mostrar_hora">
                          <time v-if="evento.hora_inicio" :datetime="`${evento.dia} ${evento.hora_inicio}`" class="hora">
                            <d-icon icon="FaClock" size="is-small" />
                            <span>
                              {{ evento.hora_inicio_format }}
                            </span>
                            <span v-if="evento.hora_fin" :date="`${evento.dia} ${evento.hora_fin}`">
                              - {{ evento.hora_fin_format }}
                            </span>
                          </time>
                        </span>
                        <span v-if="evento.lugar" class="lugar">
                          <d-icon icon="FaLocationDot" size="is-small" /> 
                          {{ evento.lugar }}
                        </span>
                      </div>

                      <p v-if="evento.titulo" class="title is-size-4 pb-2 mb-0">{{ evento.titulo }}</p>
                      <p v-if="evento.descripcion" class="subtitle is-size-6 mt-0">{{ evento.descripcion }}</p>

                      <div v-if="evento.oradores.length != 0" class="columns is-mobile">
                        <div v-if="evento.oradorSeleccionado != null" class="column oradorSeleccionado">
                          <strong>{{ evento.oradorSeleccionado.nombre }}</strong>
                          <div class="content">{{ evento.oradorSeleccionado.cargo }}</div>
                        </div>
                        <div class="column oradores pl-0">
                          <div class="orador" v-for="orador in evento.oradores" :key="orador.id"
                            :class="{ selected: evento.oradorSeleccionado.id == orador.id }"
                            @click="SeleccionarOrador(evento.id, orador.id)">
                            <div class="image" v-if="orador.img_url">
                              <img class="is-rounded" :src="orador.img_url">
                            </div>
                          </div>
                        </div>
                      </div>
                      <b-button v-if="evento.simultaneo" @click="agendaActiveTab = 1" class="ver-salas is-ghost p-0">
                        Ver salas
                        <d-icon icon="ChevronRight" size="is-small" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          <!-- </b-tab-item> -->
          <!-- <b-tab-item label="Salas" class="px-0">
            <div class="item sala py-4" v-for="el in salas" :key="el.id">
              <div class="title is-size-5 has-text-weight-bold">
                {{ el.dia }} | {{ el.fecha }}
                {{ el.horario ? `| ${el.horario}` : ''}}</div>
              <div v-for="grupo in el.grupos" :key="grupo.id" class="mb-3">
                <div class="is-size-6 has-text-weight-bold">{{ grupo.titulo }}</div>
                <p v-for="el in grupo.equipos" :key="el.id">
                  <span v-if="el.titulo">{{ el.titulo }}</span> 
                  <span v-if="el.titulo && el.sala" class="mx-1">|</span> 
                  <span v-if="el.sala">{{ el.sala }}</span>
                </p>
              </div>
            </div>
          </b-tab-item>-->
        </b-tabs> 
      </div>

    </section>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      entries: [],
      oradores: [],
      dias: [],
      salas: [],
      agendaActiveTab: 0,
      diaActiveTab: null,
      videoPlayer: null,
      currVideoId: null,
      supabaseUrl: "https://fwkpkklqpdzaatdywoxw.supabase.co/rest/v1/",
      supabaseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ3a3Bra2xxcGR6YWF0ZHl3b3h3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk5MjMyNjYsImV4cCI6MTk5NTQ5OTI2Nn0.ZzCqXIleMLsPu7jKJWh9pJnxr698TA3rHDyDDVKZYzg",
      meses: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
  computed: {
    srcHeaderMenu() {
      return this.$store.state.setup.headerLogo.sizes.mobile.url || this.$store.state.setup.headerLogo.url
    }
  },
  methods: {
    SeleccionarOrador(eventoId, oradorId) {
      let evento = this.entries.find(x => x.id == eventoId);
      let orador = this.oradores.find(x => x.id == oradorId);
      evento.oradorSeleccionado = orador;
      this.$forceUpdate(); // feo pero necesario
    },
    FormatDate(date) {
      // La fecha viene en format YYYY/mm/dd
      // Y la queremos cambiar a dd de MES
      const d = new Date(date);
      let mes = this.meses[d.getMonth()]
      return `${d.getDate()+1} de ${mes}`;
    },
    EventosInDia(index) {
      return this.entries.filter(x => x.dia == this.dias[index || 0])
    },
    async GetCalendario() {
      if (this.entries.length == 0) {
        this.$store.commit("StartLoading")
        let salasQuery = await axios.get(`${this.supabaseUrl}salas`, {
          headers: {
            'apikey': this.supabaseKey,
          }
        })
        this.salas = salasQuery.data.sort((a, b) => (a.id > b.id) ? 1 : -1)
        let oradoresQuery = await axios.get(`${this.supabaseUrl}oradores`, {
          headers: {
            'apikey': this.supabaseKey,
          }
        })
        this.oradores = oradoresQuery.data
        let res = await axios.get(`${this.supabaseUrl}calendario_douob`, {
          headers: {
            'apikey': this.supabaseKey,
          }
        })
        if (res.data) {
          this.entries = res.data
          this.dias = this.entries.map(x => x.dia)
          this.dias = this.dias.filter((v, i, a) => a.indexOf(v) === i);
          this.dias.sort()
          // Agrego timestamp de inicio y fin
          this.entries.forEach(element => {
            
            if(element.hora_inicio) {
              element.tsStart = new Date(`${element.dia}T${element.hora_inicio.split('-')[0]}`).getTime()
              element.hora_inicio_format = new Date(element.tsStart).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            }

            if(element.hora_fin) {
              element.tsFinish = new Date(`${element.dia}T${element.hora_fin.split('-')[0]}`).getTime()
              element.hora_fin_format = new Date(element.tsFinish).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            }

            element.activo = false
            element.yapaso = false
            // Completo el array de oradores
            element.oradores = []
            if (element.oradores_id) {
              element.oradores_id.forEach(oradorId => {
                element.oradores.push(this.oradores.find(x => x.id == oradorId))
              })
              element.oradorSeleccionado = element.oradores[0]
            } else {
              element.oradorSeleccionado = null
            }
          });
          // Ordeno todo por timestamp de inicio
          this.entries.sort((a, b) => {
            return a.tsStart - b.tsStart;
          });
          // Active tab
          let currDateFormated = new Date().toISOString().split('T')[0] // Fecha de hoy en format YYYY-MM-DD
          let dateIndex = this.dias.indexOf(currDateFormated) // Me fijo si la fecha actual está en el array de días
          this.$nextTick(() => {
            if (dateIndex >= 0) {
              this.diaActiveTab = dateIndex // Seteo el tab activo al indice del dia actual
            }
          });
          this.CheckActiveEvents()
        }
        this.$store.commit("StopLoading")
      }
    },
    CheckActiveEvents() {
      let needsUpdate = false
      let now = new Date().getTime()
      this.entries.forEach(element => {
        let activo = now >= element.tsStart && now < element.tsFinish
        if (element.activo != activo) {
          needsUpdate = true // tengo que forzar porque sino no actualiza
        }
        element.activo = activo

        let yapaso = now > element.tsFinish
        if (element.yapaso != yapaso) {
          needsUpdate = true // tengo que forzar porque sino no actualiza
        }
        element.yapaso = yapaso

      });
      if (needsUpdate) {
        this.$forceUpdate();
      }
      setTimeout(() => {
        this.CheckActiveEvents()
      }, 10000);
    },
  },
  async mounted() {
    this.GetCalendario()
  }
}
</script>

<style scoped lang="scss">
.card {
  box-shadow: none;
}

.card.evento {
  transition: all .5s;

  .card-content {
    border-bottom: dashed 1px #DBDBDB
  }

  .horaylugar {
    color: var(--primary-color);
    font-weight: bold;

    & > span:nth-child(2) {
      border-left: solid 1px #CCC;
      margin-left: .5em;
      padding-left: .5em;
    }

    .icon {
      position: relative;
      top: 3px;
    }
  }

  .button.is-ghost {
    font-weight: bold;
    color: var(--primary-color);

    .icon {
      position: relative;
      top: 1px;
    }
  }
}

.sala {
  border-bottom: dashed 1px #DBDBDB;

  &:first-of-type{
    padding-top: 0 !important;
  }
}

.card-header {
  background-color: #F5F5F5;
  box-shadow: none;
}

.card-header-title {
  font-weight: normal;

  span.spacer {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin-right: 0.4em;
    background: black;
  }
}

.card-header-icon .icon {
  transform: rotate(0);
  transition: transform 200ms;

  &.open {
    transform: rotate(-180deg);
  }
}

// .evento {

//   &.active {
//     border: 2px solid;
//   }

//   &.old {
//     opacity: .4;
//   }

// }

.oradorSeleccionado {
    line-height: 1.1;
}

.oradores {
  display: flex;

  .orador {
    max-width: 180px;
    margin-right: -8px;
    opacity: .5;
    filter: grayscale(1);
    transition: opacity .3s;

    &.selected {
      opacity: 1;
    }

    .image {
      width: 45px;
      height: 45px;
    }
  }
}

.circuloTitulo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--primary-color);
}

.circuloTituloInner {
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.collapse.card {
  &:nth-of-type(1) {
    --primary-color: #F5B317;
  }

  &:nth-of-type(2) {
    --primary-color: #00A0DF;
  }

  &:nth-of-type(3) {
    --primary-color: #9D1D96;
  }


}
</style>

<!-- Manual Scoped: (algunos componentes se renderizan por fuera del scoped de Vue) -->
<style lang="scss">
.agenda {
	section.tab-content {
		height: calc(100% - 41px);
		overflow-x: hidden;
		overflow-y: auto;
    padding: 1.5rem;
	}
	
	.b-tabs {
		height: 100%;
		overflow: hidden;
	}
	
	.tabs-parent {
		height: 100%;
	}
}
</style>